import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ currentPage, lastPage, onPageChange }) => {
  const renderPaginationItems = () => {
    let items = [];

    if (lastPage <= 6) {
      // Show all pages if there are 6 or fewer
      for (let number = 1; number <= lastPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => onPageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      // Show first, last, current and ellipses if necessary
      items.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => onPageChange(1)}
        >
          1
        </Pagination.Item>
      );

      let startPage = Math.max(2, currentPage - 2);
      let endPage = Math.min(lastPage - 1, currentPage + 2);

      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => onPageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < lastPage - 1) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }

      items.push(
        <Pagination.Item
          key={lastPage}
          active={lastPage === currentPage}
          onClick={() => onPageChange(lastPage)}
        >
          {lastPage}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination className="justify-content-center">
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {renderPaginationItems()}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === lastPage}
      />
    </Pagination>
  );
};

export default PaginationComponent;
