import React, { useRef, useState, useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import Loader from "../../Loader/Loader";
import { get, httpOnlyConfig, post, upload } from "../../../utils/DeApi";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";

const AssessmentCreate = () => {
  const navigate = useNavigate();
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const promises = subscribedPromises.current;
    fetchProperties();
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const fetchProperties = () => {
    setIsLoading(true);
    setError();

    const propertyPromise = get("properties");

    propertyPromise.promise
      .then((response) => {
        setProperties(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(propertyPromise);
  };

  const handleCreateAssessment = (formValues) => {
    const property = formValues.propertyId;
    property ? createAssessment(formValues) : createProperty(formValues);
  };

  const createProperty = async (formValues) => {
    setError();
    setIsLoading(true);
    let createPropertyPromise;
    try {
      let filetoBeUploaded = formValues.propertyImage;

      if (
        formValues.propertyImage.type.startsWith("image/") &&
        formValues.propertyImage.type.split("/")[1] !== "heic"
      ) {
        filetoBeUploaded = await imageCompression(formValues.propertyImage, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });
        createPropertyPromise = upload("properties", {
          name: formValues.propertyName,
          description: formValues.propertyDescription,
          image: new File(
            [await filetoBeUploaded],
            formValues.propertyImage.name,
            {
              type: "image/jpeg",
              lastModified: formValues.propertyImage.lastModified,
              size: filetoBeUploaded.size,
            }
          ),
          ...httpOnlyConfig,
        });
      }

      if (formValues.propertyImage.type.split("/")[1] === "heic") {
        const blob = new Blob([formValues.propertyImage], {
          type: "image/jpeg",
          size: formValues.propertyImage.size,
        });

        const url = URL.createObjectURL(blob);

        const blobUrl = await fetch(url);

        const resBlob = await blobUrl.blob();

        const heicJpg = await heic2any({
          blob: await resBlob,
          toType: "image/jpeg",
          quality: 100,
        });

        const conversionResult = await heicJpg;

        filetoBeUploaded = await imageCompression(conversionResult, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });

        createPropertyPromise = upload("properties", {
          name: formValues.propertyName,
          description: formValues.propertyDescription,
          image: new File(
            [await filetoBeUploaded],
            formValues.propertyImage.name.replace(/\.heic$/, ".jpeg"),
            {
              type: "image/jpeg",
              lastModified: formValues.propertyImage.lastModified,
              size: filetoBeUploaded.size,
            }
          ),
          ...httpOnlyConfig,
        });
      }
    } catch (error) {
      console.log(error);
    }

    createPropertyPromise.promise
      .then((response) => {
        const assessmentObject = {
          assessmentName: formValues.assessmentName,
          assessmentDescription: formValues.assessmentDescription,
          propertyId: response.data.id,
        };
        createAssessment(assessmentObject);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPropertyPromise);
  };

  const createAssessment = (formValues) => {
    setIsLoading(true);
    setError();

    const assessmentBody = {
      name: formValues.assessmentName,
      description: formValues.assessmentDescription,
      property_id: formValues.propertyId,
    };

    const createAssessmentPromise = post(`assessments`, {
      ...assessmentBody,
    });

    createAssessmentPromise.promise
      .then((response) => {
        navigate(`assessments/${response.data.id}`);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(createAssessmentPromise);
  };

  const AssessmentSchema = yup.object().shape({
    assessmentName: yup.string().min(2, "Too Short").required("Required"),
    assessmentDescription: yup.string().min(2, "Too Short"),
    propertyId: yup.string(),
    propertyName: yup
      .string()
      .min(2, "Too Short")
      .notOneOf(
        properties.map((item) => item.name),
        "A propery with the same name already exists."
      )
      .when("propertyId", {
        is: (val) => !val,
        then: (schema) => schema.required(),
      }),
    propertyDescription: yup.string().min(2, "Too Short"),
    propertyImage: yup.mixed().when("propertyId", {
      is: (propertyId) => !propertyId,
      then: yup.string().required("Image is required"),
    }),
  });

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">Create Assessment</Tooltip>}
      >
        <Button
          variant="outline-success"
          className=" border-secondary border-opacity-10 me-2"
          onClick={() => {
            setShow(true);
            setError();
          }}
          size="sm"
        >
          <span className="material-symbols-outlined ">add</span>
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              assessmentName: "",
              assessmentDescription: "",
              propertyId: "",
              propertyName: "",
              propertyDescription: "",
              propertyImage: "",
            }}
            validationSchema={AssessmentSchema}
            onSubmit={(values) => {
              console.log("Entering submitForm()");

              if (values) {
                // Safely access properties on formValues
                handleCreateAssessment(values);
                // Rest of your code
              } else {
                console.log("formValues is undefined");
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              errors,
              touched,
              isValid,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="assessmentName">
                  <Form.Label>Assessment Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="assessmentName"
                    value={values.assessmentName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.assessmentName && !errors.assessmentName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.assessmentName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="assessmentDescription" className="my-3">
                  <Form.Label>Assessment Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="assessmentDescription"
                    value={values.assessmentDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.assessmentDescription &&
                      !errors.assessmentDescription
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.assessmentDescription}
                  </Form.Control.Feedback>
                </Form.Group>

                {properties && !!properties.length && (
                  <Form.Group controlId="propertyId" className="my-3">
                    <Form.Label>Select a Property</Form.Label>
                    <Form.Select
                      name="propertyId"
                      value={values.propertyId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.propertyId && !errors.propertyId}
                    >
                      <option value={""}> New Property</option>
                      {properties.map((property, key) => (
                        <option key={key} value={property.id}>
                          {property.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.propertyId}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                {!values.propertyId && (
                  <div className="ms-3 p-3 border">
                    <Form.Group controlId="propertyName">
                      <Form.Label>Property Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="propertyName"
                        value={values.propertyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.propertyName && !errors.propertyName}
                        isInvalid={touched.propertyName && errors.propertyName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.propertyName}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      controlId="propertyDescription"
                      className="my-3"
                    >
                      <Form.Label>Property Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="propertyDescription"
                        value={values.propertyDescription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={3}
                        isValid={
                          touched.propertyDescription &&
                          !errors.propertyDescription
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.propertyDescription}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="propertyImage" className="mt-2">
                      <Form.Label>Property Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="propertyImage"
                        onChange={(event) => {
                          setFieldValue(
                            "propertyImage",
                            event.currentTarget.files[0]
                          );
                        }}
                        onBlur={handleBlur}
                        isValid={touched.propertyImage && !errors.propertyImage}
                        isInValid={
                          touched.propertyImage && errors.propertyImage
                        }
                        accept=".png,.jpg,.jpeg,.heic"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.propertyImage}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}

                <Button
                  variant="primary"
                  type="submit"
                  className="my-3"
                  disabled={
                    !isValid ||
                    !values.assessmentName ||
                    isLoading ||
                    (!values.propertyId && !values.propertyName)
                  }
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
          {error && <ErrorHandler error={error} />}
          {isLoading && <Loader />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssessmentCreate;
