import React, { useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";

import "./ErrorHandler.scss";
import { logOut } from "../../utils/BeeApi";

const ErrorHandler = ({ error, message }) => {
  const navigate = useNavigate();
  let status = "";
  let statusText = "Oh snap! You got an error!";
  let _message = "Something unexpected happened. Please try again.";

  const [show, setShow] = useState(true);

  if (error) {
    status = error.status || status;
    statusText = error.statusText || statusText;

    if (
      error.status === 401 &&
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/reset-password"
    ) {
      window.location.replace("/logout");
      logOut();
    }

    if (error.data) {
      if (error.data.error) {
        if ("message" in error.data.error) {
          _message = error.data.error.message;
        } else {
          _message =
            error.data.error[Object.keys(error.data.error)[0]] ?? _message;
        }
      } else {
        if ("message" in error.data) {
          _message = error.data.message;
        }
      }
    }
  }
  return (
    <>
      {createPortal(
        <>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            autohide
            delay={1000000}
            className={` rounded text-danger  position-fixed translate-middle start-50 border-0 border-start border-5 border-danger bg-white ${
              show ? "active" : "inactive"
            }`}
            style={{ zIndex: 10000 }}
          >
            <Toast.Body
              className={`rounded bg-danger bg-opacity-10 text-start `}
            >
              <h5 className="d-flex align-items-center justify-content-between">
                <span>
                  <span className="material-icons-outlined text-danger me-2">
                    error
                  </span>

                  <small className="text-danger"> {statusText}</small>
                </span>
                <Button
                  variant="close"
                  size="sm"
                  onClick={() => setShow(false)}
                ></Button>
              </h5>
              <p className="text-start ms-1">
                <span>{message || _message}</span>
              </p>
              <small className="text-start ms-1">Error Code {status}</small>
            </Toast.Body>
          </Toast>
        </>,
        document.body
      )}
    </>
  );
};

export default ErrorHandler;
