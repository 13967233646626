import React, { useContext } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/UserProvider";
import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import Help from "../Help/Help";
import Feedback from "../Feedback/Feedback";

const Header = () => {
  const { user } = useContext(UserContext);

  return (
    <Navbar
      bg="white"
      variant="light"
      expand="lg"
      fixed="top"
      className="MainHeader fixed-top border-bottom"
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="text-primary">
          <img
            src="/icast_logo.png"
            className="img-responsive pe-2"
            height={40}
            alt="ERM"
            loading="lazy"
          />
          <span className="border-start border-2 ps-2 text-center">
            EnergyIntel
          </span>
        </Navbar.Brand>
        <Navbar.Toggle />

        <Navbar.Collapse className="justify-content-end text-end">
          <Nav>
            <Feedback />
            <Help />
            <NavDropdown align="end" title={`Hi, ${user?.firstName}`}>
              <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/history">
                History
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/logout">
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
