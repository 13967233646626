import React, { useState, useRef, useEffect } from "react";
import { get } from "../../../utils/DeApi";
import {
  Card,
  Alert,
  Table,
  Badge,
  Pagination,
  Container,
  Placeholder,
} from "react-bootstrap";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import BreadCrumbs from "../../App/BreadCrumbs/BreadCrumbs";
import Header from "../Header/Header";

const History = () => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [assessment, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const promises = subscribedPromises.current;
    fetchAllAssessmentHistory();
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchAllAssessmentHistory = () => {
    setIsLoading(true);
    setError();

    const historyPromise = get(`assessments`);

    historyPromise.promise
      .then((response) => {
        setIsLoading(false);
        setAssessments(response?.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(historyPromise);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAssessments = assessment.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  return (
    <>
      <Header />

      <Container className="MainContent py-5">
        <div className="mx-4 mb-5">
          <BreadCrumbs
            breadcrumbs={[
              { name: "Dashboard", link: "/" },
              {
                name: "Assessment History",
                link: `/history`,
                active: true,
              },
            ]}
          />
        </div>

        <Card className="mx-4 my-2 mt-2 border-0 table-history shadow">
          <Card.Header>
            <Card.Title>Assessment History</Card.Title>
          </Card.Header>
          <div className="table-responsive">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>S.No#</th>
                  <th>Assessments</th>
                  <th>Property Name</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {!currentAssessments.length > 0 &&
                  isLoading &&
                  Array(10)
                    .fill(0)
                    .map((ele, placeholderKey) => (
                      <tr key={Math.random() + placeholderKey}>
                        {Array(7)
                          .fill(0)
                          .map((elem, key) => (
                            <Placeholder
                              as="td"
                              animation="glow"
                              key={key + "placeholder"}
                            >
                              <Placeholder xs={12} className="rounded-pill " />
                            </Placeholder>
                          ))}
                      </tr>
                    ))}
                {currentAssessments &&
                  currentAssessments.length > 0 &&
                  currentAssessments.map((history, index) => {
                    const id = indexOfFirstItem + index;
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{capitalizeFirstLetter(history.name)}</td>
                        <td>{history.property.name}</td>
                        <td>{history?.email}</td>
                        <td>{new Date(history?.createdAt).toLocaleString()}</td>
                        <td>
                          <sub className="h-100">
                            <Badge
                              style={{ fontSize: 10 }}
                              bg={
                                history?.status?.status ===
                                "Assessment Completed"
                                  ? "success"
                                  : "primary"
                              }
                              className="rounded-pill ms-1"
                            >
                              {history?.status?.status}
                            </Badge>
                          </sub>
                        </td>
                        <td>{new Date(history?.updatedAt).toLocaleString()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>

          {error && <ErrorHandler error={error} />}

          {!isLoading && assessment && !assessment.length && (
            <Alert variant="info" className="mx-auto my-5 w-90">
              No assessments are created.
            </Alert>
          )}
          <Card.Footer className="py-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="me-3">Total Records: {assessment.length}</div>
                <div className="d-flex align-items-center">
                  <span className="me-2">Show:</span>
                  <select
                    className="form-select form-select-sm"
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              <Pagination
                size="sm"
                className="d-flex justify-content-end align-items-center mb-0"
              >
                <Pagination.Prev
                  onClick={() =>
                    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                  }
                  disabled={currentPage === 1}
                />
                {Array.from({
                  length: Math.ceil(assessment.length / itemsPerPage),
                }).map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(assessment.length / itemsPerPage)
                      )
                    )
                  }
                  disabled={indexOfLastItem >= assessment.length}
                />
              </Pagination>
            </div>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

export default History;
