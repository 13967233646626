import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";
import "./SignUp.scss";
import TextLogo from "../TextLogo/TextLogo";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { isValidEmail, isValidPassword } from "../../../utils/StringUtils";

const Signup = () => {
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [emailValid, setEmailValid] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [lastNameValid, setLastNameValid] = useState();
  const [passwordValid, setPasswordValid] = useState();
  const [firstNameValid, setFirstNameValid] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  function handleFirstNameChange(event) {
    let _firstName = event.target.value;
    setFirstName(_firstName);
    setFirstNameValid(_firstName.length > 2);
  }

  function handleLastNameChange(event) {
    let _lastName = event.target.value;
    setLastName(_lastName);
    setLastNameValid(_lastName.length > 2);
  }

  function handleEmailChange(event) {
    let _email = event.target.value;
    setEmail(_email);
    setEmailValid(isValidEmail(_email));
  }

  function handlePasswordChange(event) {
    let _password = event.target.value;
    setPassword(_password);
    setPasswordValid(isValidPassword(_password));
  }

  function toggleVisiblePassword() {
    setShowPassword(!showPassword);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setFormSubmitted(true);
    if (emailValid && passwordValid && firstNameValid && lastNameValid) {
      setIsLoading(true);
      setError();
      setSuccess(true);
    }
  }

  const renderRegistrationForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="text-center">
          <h2 className="pre-title">Create an account</h2>
        </div>
        <hr />
        <Row>
          <Form.Group className="mb-3">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              isInvalid={!firstNameValid && formSubmitted}
              placeholder="Enter first name"
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              First name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              isInvalid={!lastNameValid && formSubmitted}
              placeholder="Enter last name"
            />
            <Form.Control.Feedback type="invalid">
              Last name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!emailValid && formSubmitted}
              placeholder="Enter email"
            />
            <Form.Control.Feedback type="invalid">
              This should be a valid email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                isValid={!passwordValid && formSubmitted}
                placeholder="Password"
              />
              <Button
                variant="light"
                className="border-top border-end border-start pt-0 border-bottom"
                onClick={toggleVisiblePassword}
              >
                {showPassword ? (
                  <span className="material-icons-outlined text-muted">
                    visibility_off
                  </span>
                ) : (
                  <span className="material-icons-outlined text-muted">
                    visibility
                  </span>
                )}
              </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Password must contain at least 6 characters, including
              UPPER/lowercase and numbers.
            </Form.Control.Feedback>
          </Form.Group>
          {error &&
            ((error) => {
              if (error.status === 409) {
                return (
                  <Alert variant="warning" className="mt-md mb-md">
                    <h3>Conflict</h3>
                    <p>
                      An account associated with this email address already
                      exists. Please use a different email address.
                    </p>
                  </Alert>
                );
              } else {
                return <ErrorHandler error={error} />;
              }
            })(error)}
          {success && (
            <Alert variant="success">
              <h4>Success!</h4>
              <p>
                Your account has been created,{" "}
                <Link to="" className="font-weight-bold alert-success">
                  click here to login.
                </Link>
              </p>
            </Alert>
          )}
          <Col md={12}>
            <div className="d-grid gap-2 mb-3">
              <Button
                type="submit"
                color="primary"
                disabled={isLoading || !(passwordValid && emailValid)}
              >
                {isLoading && (
                  <Spinner
                    className="me-2"
                    animation="border"
                    size="sm"
                    variant="light"
                  />
                )}
                Create Account
              </Button>
            </div>
            <p className="text-center pt-3">
              <Link to="">Already have an account?</Link>
            </p>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Container fluid className="Login mt-5 mb-5">
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <TextLogo />
          <Card className="border-0 shadow-sm">
            <Card.Body>{renderRegistrationForm()}</Card.Body>
          </Card>
          <p className="text-center text-sm pt-2 text-muted">
            {" "}
            &copy; ICAST {new Date().getFullYear()}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
