import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Loader from "../../Loader/Loader";
import { get, upload, httpOnlyConfig } from "../../../utils/DeApi";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import {
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Offcanvas,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import PropertyDelete from "./PropertyDelete/PropertyDelete";
import PropertyUpdate from "./PropertyUpdate/PropertyUpdate";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import PaginationComponent from "../AssessmentList/PaginationComponent";

const AssessmentProperties = () => {
  const subscribedPromises = useRef([]);
  const isMobileScreen = useCheckMobileScreen();

  const [properties, setProperties] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    total: 0,
  });

  const handleApplyFilters = () => {
    setIsLoading(true);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    fetchProperties({
      selectedProperty,
      _page: 1,
    });
  };

  const handleResetFilters = () => {
    setIsLoading(true);
    setSelectedProperty([]);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    fetchProperties({ _page: 1 });
  };

  const handleClose = () => {
    setShow(false);
  };
  const toggleShow = () => setShow((s) => !s);
  const handleAddModalClose = () => {
    setShowAddModal(false);
    setSuccess(false);
  };
  const handleAddModalShow = () => {
    setShowAddModal(true);
    setError();
  };

  useEffect(() => {
    fetchProperties({ selectedProperty, _page: pagination.currentPage });

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, [show]);

  const fetchProperties = ({
    selectedProperty = null,
    _page = pagination.currentPage,
  } = {}) => {
    setIsLoading(true);
    setError();

    const propertyNameParam = selectedProperty
      ? `&search=${selectedProperty}`
      : "";
    const propertyPromise = get(`properties?page=${_page}${propertyNameParam}`);
    const propertyListPromise = get("properties?list=true");

    Promise.all([propertyPromise.promise, propertyListPromise.promise])
      .then(([propertiesResponse, propertiesListResponse]) => {
        const sortedProperties = propertiesResponse.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        const sortedPropertiesList = propertiesListResponse.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setProperties(() => sortedProperties);
        setPropertyList(() => sortedPropertiesList);
        setPagination({
          currentPage: propertiesResponse.meta.current_page,
          lastPage: propertiesResponse.meta.last_page,
          perPage: propertiesResponse.meta.per_page,
          total: propertiesResponse.meta.total,
        });
      })
      .catch((error) => {
        if (!error.isCanceled) setError(error);
      })
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(propertyPromise, propertyListPromise);
  };

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    fetchProperties({ selectedProperty, _page: page });
  };

  const handleAddProperty = async (values, { setSubmitting, resetForm }) => {
    setError();
    setIsLoading(true);

    let propertyData = {
      name: values.name,
      description: values.description,
      ...httpOnlyConfig,
    };

    try {
      let fileToBeUploaded = values.image;

      if (
        values.image.type.startsWith("image/") &&
        values.image.type.split("/")[1] !== "heic"
      ) {
        fileToBeUploaded = await imageCompression(values.image, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });
        propertyData.image = new File(
          [await fileToBeUploaded],
          values.image.name,
          {
            type: "image/jpeg",
            lastModified: values.image.lastModified,
            size: fileToBeUploaded.size,
          }
        );
      }

      if (values.image.type.split("/")[1] === "heic") {
        const blob = new Blob([values.image], {
          type: "image/jpeg",
          size: values.image.size,
        });

        const url = URL.createObjectURL(blob);

        const blobUrl = await fetch(url);

        const resBlob = await blobUrl.blob();

        const heicJpg = await heic2any({
          blob: await resBlob,
          toType: "image/jpeg",
          quality: 100,
        });

        const conversionResult = await heicJpg;

        fileToBeUploaded = await imageCompression(conversionResult, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });

        propertyData.image = new File(
          [await fileToBeUploaded],
          values.image.name.replace(/\.heic$/, ".jpeg"),
          {
            type: "image/jpeg",
            lastModified: values.image.lastModified,
            size: conversionResult.size,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }

    const createPropertyPromise = upload(`/properties`, propertyData);

    createPropertyPromise.promise
      .then((response) => {
        resetForm();
        setSuccess(`Property "${values.name}" added successfully.`);
        setTimeout(() => {
          setShowAddModal(false);
          setSuccess(false);
          fetchProperties();
        }, 3000);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPropertyPromise);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short")
      .notOneOf(
        properties.map((item) => item.name),
        "A property with the same name already exists."
      )
      .required("Property Name is Required"),
    description: Yup.string().min(2, "Too Short"),
    image: Yup.mixed()
      .required("Image is required")
      .test(
        "fileType",
        "Unsupported file format. Please upload a JPEG or PNG image.",
        (value) => {
          return (
            value &&
            ["image/jpeg", "image/png", "image/heic"].includes(value.type)
          );
        }
      ),
  });

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">View Properties</Tooltip>}
      >
        <Button
          size="sm"
          variant="outline-primary"
          className={`border-secondary border-opacity-10 ${
            isMobileScreen ? "mx-1 my-2" : "me-2"
          }`}
          onClick={toggleShow}
        >
          <span className="material-symbols-outlined">domain</span>
        </Button>
      </OverlayTrigger>

      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        placement="end"
        style={{ width: `${isMobileScreen ? "100%" : "40%"}` }}
      >
        <div className="sticky-top bg-white" style={{ zIndex: 1050 }}>
          <Offcanvas.Header
            closeButton
            className="d-flex justify-content-between align-items-center"
          >
            <Offcanvas.Title>Assessment Properties</Offcanvas.Title>
            <div className="d-flex align-items-center ms-auto">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">Add New Property</Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="border-secondary border-opacity-10 ms-2 me-4"
                  onClick={handleAddModalShow}
                >
                  <span className="material-symbols-outlined">add</span>
                </Button>
              </OverlayTrigger>
            </div>
          </Offcanvas.Header>

          <div className="px-4 mt-2">
            {isLoading && <Loader />}
            {!isLoading && (
              <>
                <Form.Group className="mb-4">
                  <Form.Label>Filter Property Name</Form.Label>
                  <Typeahead
                    selected={selectedProperty}
                    multiple
                    options={propertyList?.map((property) => property?.name)}
                    id="property"
                    placeholder="Choose a property Name..."
                    onChange={(selected) => {
                      setSelectedProperty(selected);
                    }}
                  />
                </Form.Group>
                <div className="d-flex flex-column mt-3">
                  <Button
                    variant="light"
                    className="mb-2"
                    onClick={handleResetFilters}
                  >
                    Reset Filters
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleApplyFilters}
                    disabled={isLoading}
                  >
                    Apply Filters
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>

        <Offcanvas.Body
          className="px-4"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          {error && <ErrorHandler error={error} />}
          {!isLoading &&
            properties &&
            !!properties.length &&
            properties.map((property, key) => (
              <Row className="py-3 align-baseline" key={key}>
                <Col md={8} xs={12}>
                  <div>
                    <h5 className="text-primary">{property.name}</h5>
                    <p className="text-muted">{property.description}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-md-end mt-2">
                    <PropertyUpdate
                      property={property}
                      propertyUpdated={fetchProperties}
                    />{" "}
                    <PropertyDelete
                      property={property}
                      propertyDeleted={fetchProperties}
                    />
                  </div>
                </Col>
              </Row>
            ))}
          {properties && !!properties.length && !isLoading && (
            <Row className="justify-content-center">
              <Col xs="auto">
                <PaginationComponent
                  currentPage={pagination.currentPage}
                  lastPage={pagination.lastPage}
                  onPageChange={handlePageChange}
                />
              </Col>
            </Row>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showAddModal} onHide={handleAddModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Property</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleAddProperty}
          initialValues={{
            name: "",
            description: "",
            image: null,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="propertyName">
                  <Form.Label>Property Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="propertyDescription" className="mt-3">
                  <Form.Label>Property Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    isInvalid={touched.description && !!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="propertyImage" className="mt-3">
                  <Form.Label>Property Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="image"
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                    }}
                    isInvalid={touched.image && !!errors.image}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.image}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleAddModalClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !values.name ||
                    !values.description ||
                    !values.image
                  }
                >
                  Add Property
                </Button>
              </Modal.Footer>
              {success && (
                <Alert
                  variant="success"
                  className="mt-3 mb-0 text-center"
                  dismissible
                  onClose={() => setSuccess(false)}
                >
                  {success}
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AssessmentProperties;
