import React, { createContext, useEffect, useRef, useState } from "react";
import Loader from "../components/Loader/Loader";
import { get } from "../utils/BeeApi";
import { get as deGet } from "../utils/DeApi";

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);
const UserDispatchDestoryContext = createContext(undefined);

const UserProvider = ({ children }) => {
  const subscribedPromises = useRef([]);
  const location = window.location.pathname;
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();

  const fetchUser = () => {
    if (location === "/login") {
      setUser(null);
    } else {
      const userPromise = get("/user/anon");
      setIsLoading(true);

      userPromise.promise
        .then((response) => {
          const readOnly =
            response.data?.categories?.filter(
              (category) => category?.categoryName === "Read Only"
            ).length > 0
              ? true
              : false;

          const isAdmin =
            response.data?.categories?.filter(
              (category) => category?.categoryName === "Admin"
            ).length > 0
              ? true
              : false;

          setUser({
            ...response.data,
            readOnly,
            isAdmin,
          });

          setIsLoading(false);
          const userPromiseDe = deGet("/login");
          subscribedPromises.current.push(userPromiseDe);
          return userPromiseDe.promise;
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setUser(null);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(userPromise);
    }
  };

  useEffect(() => {
    fetchUser();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOUt = () => {
    setUser(null);
  };

  if (isLoading) return <Loader />;
  if (user === undefined) return <span />;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <UserDispatchContext.Provider value={fetchUser}>
        <UserDispatchDestoryContext.Provider value={logOUt}>
          {children}
        </UserDispatchDestoryContext.Provider>
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export {
  UserProvider,
  UserContext,
  UserDispatchContext,
  UserDispatchDestoryContext,
};
