import React, { useState, useEffect } from "react";
import { Button, Toast } from "react-bootstrap";
import { createPortal } from "react-dom";
import CountDownTimer from "../CountDownTimer/CountDownTimer";

const UndoDeleteToast = ({
  showToast,
  undoHandler,
  toastRef = null,
  setIsDisabled = () => {},
  message = null,
}) => {
  const [show, setShow] = useState(showToast);
  const [timeLeft, setTimeLeft] = useState(6);

  useEffect(() => {
    let timer, interval;
    document.body.style.position = "relative";

    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (timeLeft < 1) {
      setShow(false);
      setTimeLeft(6);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, timeLeft]);
  return (
    <>
      {createPortal(
        <>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            autohide
            delay={5000}
            className={`border    mx-auto  bg-light  position-fixed   start-50 translate-middle ${
              show ? "active" : "inactive"
            }`}
            style={{ zIndex: 10000 }}
          >
            <Toast.Body className={`bg-primary bg-opacity-10 `}>
              <div className="d-flex justify-content-around align-items-center">
                <>
                  <CountDownTimer />
                  <p className="py-0 my-0 ms-1 me-3 ">Deleting {message}</p>

                  <Button
                    variant="primary"
                    size="sm"
                    className="mx-3"
                    onClick={() => {
                      undoHandler();
                    }}
                  >
                    Undo
                  </Button>
                </>
              </div>
            </Toast.Body>
          </Toast>
        </>,
        document.body
      )}
    </>
  );
};

export default UndoDeleteToast;
