import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as yup from "yup";
import { destroy } from "../../../../utils/DeApi";
import UndoDeleteToast from "../../../App/UndoDeleteToast/UndoDeleteToast";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import Loader from "../../../Loader/Loader";

const PropertyDelete = ({ property, propertyDeleted }) => {
  const subscribedPromises = useRef([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let timeout;
    if (isDeleting) {
      timeout = setTimeout(() => {
        deleteProperty();
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting]);

  useEffect(() => {
    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        propertyDeleted();
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
    setError();
  };

  const undoHandler = () => {
    setIsDeleting(false);
    handleClose();
    setSuccess(false);
  };

  const deleteProperty = () => {
    setError();
    setIsLoading(true);

    const propertyPromise = destroy(`properties/${property.id}`);

    propertyPromise.promise
      .then(() => {
        setError();
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(errorHandling(error));
          setIsLoading(false);
          setIsDeleting(false);
        }
      });

    subscribedPromises.current.push(propertyPromise);
  };

  const errorHandling = (error) => {
    switch (error.status) {
      case 422:
        error.data.message = `Can't delete ${property.name} because it is linked to an assessment`;
        break;
      case 500:
        error.data.message = `Server Error! Its not you its us`;
        break;
      default:
    }

    return error;
  };

  const PropertySchema = yup.object().shape({
    propertyName: yup.string().required(),
  });

  return (
    <>
      {isDeleting && (
        <UndoDeleteToast
          showToast={isDeleting}
          undoHandler={undoHandler}
          message="Property"
        />
      )}
      <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={handleShow}
          className="border-secondary border-opacity-10"
        >
          <span className="material-symbols-outlined md-16">delete</span>
        </Button>
      </OverlayTrigger>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Property{" "}
            <b>
              <i>{property.name}</i>
            </b>
          </Modal.Title>
        </Modal.Header>

        {error && <ErrorHandler error={error} />}
        {success && (
          <Alert variant="success" className="mx-3 my-3 text-center">
            <p>Property Deleted Successfully.</p>
          </Alert>
        )}

        {property && (
          <Formik
            validationSchema={PropertySchema}
            initialValues={{
              propertyName: "",
            }}
          >
            {({
              handleChange,
              handleSubmit,
              handleBlur,
              values,
              isValid,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Group controlId="propertyName">
                    <Form.Label>
                      Please type the name of the property to confirm.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="propertyName"
                      value={values.propertyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        values.propertyName === property.name && !errors.name
                      }
                    />
                  </Form.Group>
                  {isLoading && <Loader />}
                </Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    type="submit"
                    disabled={
                      values.propertyName.trim() !== property.name ||
                      !isValid ||
                      isDeleting
                    }
                    onClick={() => setIsDeleting(true)}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

export default PropertyDelete;
