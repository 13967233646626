import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import TextLogo from "../TextLogo/TextLogo";
import { login } from "../../../utils/BeeApi";
import { login as deLogin } from "../../../utils/DeApi";
import { isValidEmail } from "../../../utils/StringUtils";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

const Login = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(
    document.location.search.replace("?", "") === "reset"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (emailValid && passwordValid) {
      setIsLoading(true);
      setError(null);
      login({ email, password })
        .then((response) => {
          return deLogin(response.data)
            .then(() => {
              window.location.replace("/");
            })
            .catch((error) => {
              setError(error);
            });
        })
        .then(() => {
          setError(null);
          setIsLoading(false);
          localStorage.removeItem("currentAssessment");
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  };

  const handleEmailChange = (event) => {
    const _email = event.target.value;
    setEmail(_email);
    setEmailValid(isValidEmail(_email));
    setFormSubmitted(false);
  };

  const handlePasswordChange = (event) => {
    const _password = event.target.value;
    setPassword(_password);
    setPasswordValid(_password.length > 0);
    setFormSubmitted(false);
  };

  const togglePasswordVisibilty = () => {
    setShowPassword(!showPassword);
  };

  const renderLoginForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        {showAlert && (
          <Alert variant="success">Password reset successfully</Alert>
        )}
        <div className="text-center">
          <h3 className="pre-title">EnergyIntel</h3>
        </div>
        <hr />
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
            autoComplete="username"
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              isInvalid={!passwordValid && formSubmitted}
              placeholder="Password"
              autoComplete="current-password"
            />
            <Button
              variant="light"
              className="border-top border-end border-start pt-0 border-bottom"
              onClick={togglePasswordVisibilty}
            >
              {showPassword ? (
                <span className="material-icons-outlined text-muted">
                  visibility_off
                </span>
              ) : (
                <span className="material-icons-outlined text-muted">
                  visibility
                </span>
              )}
            </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            This is required
          </Form.Control.Feedback>
        </Form.Group>

        {error && <ErrorHandler error={error} />}
        <div className="d-grid gap-2 mb-3">
          <Button
            type="submit"
            color="primary"
            disabled={isLoading || !(passwordValid && emailValid)}
          >
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Sign in
          </Button>
        </div>
        <p className="text-muted text-center mt-1 mb-1">
          <small>
            By continuing, you agree to our{" "}
            <a target="_blank" href="#/" rel="noreferrer">
              Terms of Service
            </a>{" "}
            and have read and acknowledge our{" "}
            <a target="_blank" href="#/" rel="noreferrer">
              Privacy Policy
            </a>
            .
          </small>
        </p>
        <hr />
        <p className="text-center">
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
      </Form>
    );
  };
  return (
    <Container className="Login mt-3 mb-3">
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 5, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <TextLogo />
          <Card className="shadow-sm">
            <Card.Body>{renderLoginForm()}</Card.Body>
          </Card>
          <p className="text-center text-sm pt-2 text-muted">
            {" "}
            &copy; EnergyIntel {new Date().getFullYear()}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
