import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import "./ContentDetails.scss";
import { get } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { Alert } from "react-bootstrap";

const ContentDetails = ({ contentId, view, onContentFetched }) => {
  const subscribedPromises = useRef([]);

  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const promises = subscribedPromises.current;
    function fetchContent(id) {
      setIsLoading(true);
      setError("");

      const contentPromise = get(`contents/${id}`, {});
      contentPromise.promise
        .then((response) => {
          setContent(response.data);
          setIsLoading(false);
          onContentFetched && onContentFetched(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(contentPromise);
    }
    fetchContent(contentId);
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  const setResponsiveness = useCallback((bodyNode) => {
    if (bodyNode !== null) {
      let aTags = bodyNode.getElementsByTagName("a");
      for (let aTag of aTags) {
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("rel", "noopener noreferrer");
      }

      let imgTags = bodyNode.getElementsByTagName("img");
      for (let img of imgTags) {
        img.setAttribute("class", "img-fluid");
      }

      let iframeTags = bodyNode.getElementsByTagName("iframe");
      for (let iframe of iframeTags) {
        iframe.removeAttribute("height");
        iframe.removeAttribute("width");
        let wrapper = document.createElement("div");
        wrapper.className = "ratio ratio-16x9";
        // insert wrapper before el in the DOM tree
        iframe.parentNode.insertBefore(wrapper, iframe);
        wrapper.appendChild(iframe);
      }

      let figureTags = bodyNode.getElementsByTagName("figureTags");
      for (let figure of figureTags) {
        figure.setAttribute("class", "img-fluid");
      }
    }
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!content) return <span />;

  return (
    <div className="my-3">
      {view === "full" && (
        <>
          <h5>{content.title}</h5>
          <hr />
        </>
      )}

      {content.contentBody && (
        <div
          className="ContentDetails"
          ref={setResponsiveness}
          dangerouslySetInnerHTML={{ __html: content.contentBody }}
        />
      )}
      {!content.contentBody && (
        <Alert
          variant="info"
          className="ContentDetails"
          ref={setResponsiveness}
        >
          <p>No information to display</p>
        </Alert>
      )}
    </div>
  );
};

ContentDetails.propTypes = {
  contentId: PropTypes.string.isRequired,
  view: PropTypes.string,
};

export default ContentDetails;
