import React, { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import { httpOnlyConfig, upload } from "../../../../utils/DeApi";
import { Modal, Button, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";

const PropertyUpdate = ({ property, propertyUpdated }) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        propertyUpdated();
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleClose = () => {
    setShow(false);
    setSuccess(false);
  };

  const handleShow = () => {
    setShow(true);
    setError();
  };

  const updateProperty = async (formValues) => {
    setError();
    setIsLoading(true);
    const { name, description, image } = formValues;
    let propertyBody = {
      name: name,
      description: description,
      image: image,
      _method: "PUT",
      ...httpOnlyConfig,
    };

    try {
      let filetoBeUploaded = image;

      if (
        image.type.startsWith("image/") &&
        image.type.split("/")[1] !== "heic"
      ) {
        filetoBeUploaded = await imageCompression(image, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });
        propertyBody.image = new File([await filetoBeUploaded], image.name, {
          type: "image/jpeg",
          lastModified: image.lastModified,
          size: filetoBeUploaded.size,
        });
      }

      if (image.type.split("/")[1] === "heic") {
        const blob = new Blob([image], {
          type: "image/jpeg",
          size: image.size,
        });

        const url = URL.createObjectURL(blob);

        const blobUrl = await fetch(url);

        const resBlob = await blobUrl.blob();

        const heicJpg = await heic2any({
          blob: await resBlob,
          toType: "image/jpeg",
          quality: 100,
        });

        const conversionResult = await heicJpg;

        filetoBeUploaded = await imageCompression(conversionResult, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1200,
          useWebWorker: true,
        });

        propertyBody.image = new File(
          [await filetoBeUploaded],
          image.name.replace(/\.heic$/, ".jpeg"),
          {
            type: "image/jpeg",
            lastModified: image.lastModified,
            size: conversionResult.size,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }

    const propertyPromise = upload(`/properties/${property.id}`, propertyBody);

    propertyPromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(propertyPromise);
  };

  const PropertySchema = yup.object().shape({
    name: yup.string().min(2, "Too Short").required("Required"),
    description: yup.string(),
    image: yup.mixed().required("You have to upload property image"),
  });

  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleShow}
          className="me-1 border-secondary border-opacity-10"
        >
          <span className="material-symbols-outlined md-18">edit</span>
        </Button>
      </OverlayTrigger>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Property</Modal.Title>
        </Modal.Header>
        {property && (
          <Formik
            validationSchema={PropertySchema}
            onSubmit={(values) => updateProperty(values)}
            initialValues={{
              propertyId: property.id,
              name: property.name,
              description: property.description || "",
              image: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              setFieldValue,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Group controlId="name" className="mb-4">
                    <Form.Label>Property Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="description" className="mt-2">
                    <Form.Label>Property Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.description && !errors.description}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="image" className="mt-2">
                    <Form.Label>Property Image</Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={(event) => {
                        setFieldValue("image", event.currentTarget.files[0]);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.image && !errors.image}
                      isInValid={touched.image && errors.image}
                      accept=".png,.jpg,.jpeg,.heic"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.image}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button size="sm" variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={!isValid || !values.image || isLoading}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
        {error && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
        {success && (
          <Alert variant="success" className="mx-3 my-3 text-center">
            <p>Property Updated Successfully.</p>
          </Alert>
        )}
      </Modal>
    </>
  );
};

export default PropertyUpdate;
