import React from "react";
import { Link } from "react-router-dom";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="text-center my-3">
    <Link to="">
      <img
        className="img-responsive"
        src="icast_logo.png"
        width={150}
        height={115}
        alt="ICAST"
        loading="lazy"
      />
    </Link>
  </div>
);

export default TextLogo;
