import { replace } from "lodash";
export function isValidEmail(text = "") {
  let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  return pattern.test(text);
}

export function isValidPassword(text = "") {
  let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/);
  return pattern.test(text);
}

export function isNotUndefined(value) {
  if (typeof value === "undefined" || value === "undefined") return "";
  return value;
}

export function roundOfNumber(number, base = 2) {
  let value = number ?? 0;

  value = value.toFixed(base);
  const commmaSeparatedValue = value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return commmaSeparatedValue;
}

export function replaceCommasInNumbers(number = "0") {
  const commmaSeparatedValue = ("" + number).replaceAll(",", "");
  if (isNaN(Number(commmaSeparatedValue))) return 0;
  return +Number(commmaSeparatedValue).toFixed(2);
}

export function validatingNumber(value) {
  return value ? replaceCommasInNumbers(value) : 0;
}

export const numberFormat = new Intl.NumberFormat("en-US");
