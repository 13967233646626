import React from "react";
import { Card, Container } from "react-bootstrap";
import { Route, Routes, useParams } from "react-router-dom";
import Footer from "../App/Footer/Footer";
import Header from "../App/Header/Header";
import ContentDetails from "./ContentDetails/ContentDetails";

const Content = () => {
  return (
    <>
      <Header title="" />
      <Container className="MainContent pt-5">
        <Routes>
          <Route path="/:contentId" element={<ContentWrapper />} />
        </Routes>
      </Container>
      <Footer />
    </>
  );
};

const ContentWrapper = () => {
  const { contentId } = useParams();
  return (
    <Card className="border-0 p-md-3">
      <Card.Body>
        <ContentDetails contentId={contentId} view="full" />
      </Card.Body>
    </Card>
  );
};

export default Content;
