import React, { memo, useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Image,
  InputGroup,
  OverlayTrigger,
  Placeholder,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Link } from "react-router-dom";
import DateRangePicker from "rsuite/DateRangePicker";
import { UserContext } from "../../../contexts/UserProvider";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import useCheckTabletScreen from "../../../hooks/useCheckTabletScreen";
import { get } from "../../../utils/DeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import AssessmentCreate from "../AssessmentCreate/AssessmentCreate";
import AssessmentProperties from "../AssessmentProperties/AssessmentProperties";
import PaginationComponent from "./PaginationComponent";

const SidebarFilters = ({
  assessments = [],
  fetchAssessments = () => {},
  isUserLoading = false,
  selectedEmail,
  selectedProperty,
  selectedEndDate,
  selectedStartDate,
  setSelectedEmail,
  setSelectedEndDate,
  setSelectedStartDate,
  setSelectedProperty,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [properties, setProperties] = useState([]);
  const [emails, setEmails] = useState([]);
  const subscribedPromises = useRef([]);

  const initialPlaceholder = "MM/DD/YYYY";
  const [placeholder, setPlaceholder] = useState(initialPlaceholder);

  useEffect(() => {
    fetchProperties();
  }, []);

  const handleApplyFilters = () => {
    fetchAssessments({
      selectedEmail,
      selectedEndDate,
      selectedStartDate,
      selectedProperty,
      _page: 1,
    });
  };

  const fetchProperties = () => {
    setIsLoading(true);
    setError();

    const propertyPromise = get("properties");
    const emailPromise = get("assessments/email/creators");
    Promise.all([propertyPromise.promise, emailPromise.promise])
      .then(([propertiesResponse, emailsResponse]) => {
        setProperties(propertiesResponse.data);
        setEmails(emailsResponse.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(propertyPromise, emailPromise);
  };

  const handleResetFilters = () => {
    setSelectedProperty([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedEmail([]);
    fetchAssessments();
  };

  const handleDateChange = (value) => {
    if (value) {
      setSelectedStartDate(value[0]);
      setSelectedEndDate(value[1]);
    } else {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  };

  return (
    <div className="sidebar">
      <Form.Group className="mb-3">
        <Form.Label>Filter Property Name</Form.Label>
        <Typeahead
          selected={selectedProperty}
          multiple
          options={properties?.map((property) => property?.name)}
          id="property"
          placeholder="Choose a property Name..."
          onChange={(selected) => {
            setSelectedProperty(selected);
          }}
        />
      </Form.Group>
      <Form.Group className="my-3">
        <Form.Label>Filter by Date Range</Form.Label>
        <DateRangePicker
          value={[selectedStartDate, selectedEndDate]}
          onChange={handleDateChange}
          format="MM/dd/yyyy"
          appearance="default"
          size="md"
          style={{ width: 280 }}
          placeholder={placeholder}
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <Form.Label>Filter by Creator's Email</Form.Label>
        <Typeahead
          options={emails}
          id="emails"
          multiple
          placeholder="Choose a Creator's Email..."
          selected={selectedEmail}
          onChange={(selected) => {
            setSelectedEmail(selected);
          }}
        />
      </Form.Group>
      <div className="d-flex flex-column mt-4">
        <Button variant="light" className=" mb-2" onClick={handleResetFilters}>
          Reset Filters
        </Button>
        <Button
          variant="primary"
          onClick={handleApplyFilters}
          disabled={isUserLoading}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
};

const CustomPlaceHolder = () => {
  const placeholder = Array(10).fill(0);
  const isMobileScreen = useCheckMobileScreen();
  return (
    <Container>
      <Row>
        <Col sm={9}>
          <div>
            {placeholder.map((placeholder, key) => (
              <Card className="mb-2 border-0 custom-placeholder" key={key}>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={4} lg={4} xl={3}>
                      <Placeholder
                        as={Card}
                        animation="glow"
                        className="border-0"
                      >
                        <Placeholder
                          style={{
                            width: isMobileScreen ? "100%" : "8.0415rem",
                            height: "5.25rem",
                          }}
                          className="rounded-1"
                          xs={3}
                        />
                      </Placeholder>
                    </Col>
                    <Col xs={8} md={8} lg={8} xl={9}>
                      <Placeholder animation="glow">
                        <Placeholder className="rounded-pill" xs={6} />
                      </Placeholder>
                      <br />
                      <Placeholder as="span" animation="glow">
                        <Placeholder className="rounded-pill" xs={4} />
                        <br />
                        <Placeholder className="rounded-pill" xs={4} />
                        <br />
                        <Placeholder className="rounded-pill" xs={3} />
                      </Placeholder>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

function AssessmentSortDropdown({ onSortChange, isLoading }) {
  const [selectedOption, setSelectedOption] = useState("latest");

  const handleSortChange = (eventKey) => {
    setSelectedOption(eventKey);
    onSortChange(eventKey);
  };

  const sortOptions = [
    { label: "Sort by Latest", value: "latest" },
    { label: "Sort by Oldest", value: "oldest" },
    { label: "Sort by Last Updated", value: "lastUpdated" },
  ];

  return (
    <DropdownButton
      id="assessment-sort-dropdown"
      title={`Sort by ${
        selectedOption === "latest"
          ? "Latest"
          : selectedOption === "lastUpdated"
          ? "Last Updated"
          : "Oldest"
      }`}
      variant="light"
      onSelect={handleSortChange}
      size="sm"
      className="d-inline"
      disabled={isLoading}
    >
      {sortOptions.map((option) => (
        <Dropdown.Item eventKey={option.value} key={option.value}>
          {option.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

const AssessmentList = () => {
  const subscribedPromises = useRef([]);
  const {
    user: { readOnly, userId },
  } = useContext(UserContext);
  const [error, setError] = useState();
  const [assessments, setAssessments] = useState([]);
  const [myAssessments, setMyAssessments] = useState([]);
  const [allAssessments, setAllAssessments] = useState([]);
  const [currentAssessment, setCurrentAssessment] = useState(
    readOnly
      ? "My Assessments"
      : localStorage.getItem("currentAssessment") || "All Assessments"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasFilterLoaded, setHasFilterLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const isMobileScreen = useCheckMobileScreen();
  const isTabletScreen = useCheckTabletScreen();
  const [showSidebar, setShowSidebar] = useState(!isMobileScreen);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    total: 0,
  });
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    fetchAssessments({
      selectedEmail,
      selectedEndDate,
      selectedStartDate,
      selectedProperty,
    });

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, currentAssessment, sortOrder]);

  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchAssessments = ({
    selectedEmail = null,
    selectedEndDate = null,
    selectedStartDate = null,
    selectedProperty = null,
    _page = null,
  } = {}) => {
    setIsLoading(true);
    setError(null);
    const searchParam = searchQuery ? `&search=${searchQuery}` : "";
    const creatorEmailParam = selectedEmail
      ? `&creator_email=${selectedEmail}`
      : "";
    const propertyNameParam = selectedProperty
      ? `&property_name=${selectedProperty}`
      : "";
    const endDateParam = selectedEndDate
      ? `&end_date=${formatDate(selectedEndDate)}`
      : "";
    const startDateParam = selectedStartDate
      ? `&start_date=${formatDate(selectedStartDate)}`
      : "";

    let sortParam = "";
    if (sortOrder && sortOrder !== "latest") {
      sortParam = `&sortby=${sortOrder}`;
    }
    if (searchQuery) {
      _page = 1;
    }
    const page = _page || searchParam ? _page : pagination.currentPage;
    const myAssessmentPromise = get(
      `assessments?assessment_type=${userId}&page=${page}${searchParam}${sortParam}${creatorEmailParam}${propertyNameParam}${startDateParam}${endDateParam}`
    );
    const allAssessmentPromise = get(
      `assessments?page=${page}${searchParam}${sortParam}${creatorEmailParam}${propertyNameParam}${startDateParam}${endDateParam}`
    );

    Promise.all([myAssessmentPromise.promise, allAssessmentPromise.promise])
      .then((results) => {
        const _allAssessments = results[1]?.data?.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }),
          _myAssessments = results[0]?.data?.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

        setAssessments(
          currentAssessment === "My Assessments"
            ? _myAssessments
            : _allAssessments
        );
        setAllAssessments(_allAssessments);
        setMyAssessments(_myAssessments);

        const paginationData =
          currentAssessment === "My Assessments"
            ? results[0]?.meta
            : results[1]?.meta;

        setPagination({
          currentPage: paginationData.current_page,
          lastPage: paginationData.last_page,
          perPage: paginationData.per_page,
          total: paginationData.total,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
        }
      });

    subscribedPromises.current.push(myAssessmentPromise);
    subscribedPromises.current.push(allAssessmentPromise);
  };

  const handleSearchClick = () => {
    fetchAssessments();
  };

  const handleSortChange = (selectedOption) => {
    let sortOrder;
    if (selectedOption === "lastUpdated") {
      sortOrder = "last_update";
    } else if (selectedOption === "oldest") {
      sortOrder = "asc";
    } else if (selectedOption === "latest") {
      sortOrder = "latest";
    }

    setSortOrder(sortOrder);
  };

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleAssessmentChange = (e) => {
    localStorage.setItem("currentAssessment", e);
    setCurrentAssessment(e);
    setSearchQuery("");
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    if (e === "My Assessments") {
      setAssessments(myAssessments);
    }
    if (e === "All Assessments") {
      setAssessments(allAssessments);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const DropDownAssessment = memo(function DropDownAssessment({ isLoading }) {
    return (
      <>
        <Dropdown className="d-inline mx-1" onSelect={handleAssessmentChange}>
          <Dropdown.Toggle
            id="dropdown-basic"
            size="sm"
            variant="light"
            disabled={isLoading || readOnly}
          >
            {currentAssessment}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="My Assessments">
              My Assessments
            </Dropdown.Item>
            <Dropdown.Item eventKey="All Assessments">
              All Assessments
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Container>
        <Row>
          {!isMobileScreen && (
            <Col sm={12} md={12} lg={3}>
              <Card className="border-0 bg-white position-sticky sticky-header">
                <h5 className="border-bottom border-opacity-25 border-secondary pb-3 ps-2 pt-3">
                  Filters
                </h5>
                <Card.Body>
                  <SidebarFilters
                    fetchAssessments={fetchAssessments}
                    assessments={allAssessments}
                    isUserLoading={isLoading}
                    selectedProperty={selectedProperty}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    selectedEmail={selectedEmail}
                    setSelectedEmail={setSelectedEmail}
                    setSelectedEndDate={setSelectedEndDate}
                    setSelectedProperty={setSelectedProperty}
                    setSelectedStartDate={setSelectedStartDate}
                  />
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col sm={12} md={12} lg={9} className="bg-white">
            <Row className=" align-items-center  position-sticky row sticky-header bg-white shadow-sm flex-md-wrap mb-4 py-3">
              <Col sm={12} md={4} lg={3}>
                <h4 className={`${isMobileScreen ? "mb-2" : "py-1 d-inline"}`}>
                  {currentAssessment}
                </h4>
              </Col>
              <Col
                sm={12}
                md={8}
                lg={4}
                className={`${
                  isTabletScreen ? "d-flex order-md-last" : "d-flex"
                }`}
              >
                <InputGroup>
                  <Form.Control
                    type="search"
                    placeholder="Search by Assessment Name"
                    value={searchQuery}
                    className={`${isMobileScreen ? "mb-2" : ""}`}
                    onChange={handleSearchChange}
                    disabled={isLoading}
                  />
                  <Button
                    variant="light"
                    onClick={handleSearchClick}
                    disabled={!searchQuery || isLoading}
                  >
                    <i className="material-icons-outlined">search</i>
                  </Button>
                </InputGroup>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <div
                  className={`${isMobileScreen ? "py-1 d-inline" : "d-flex"}`}
                >
                  <AssessmentSortDropdown
                    onSortChange={handleSortChange}
                    isLoading={isLoading}
                  />
                  <DropDownAssessment isLoading={isLoading} />
                  {isMobileScreen && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="button-tooltip-2">Filters</Tooltip>}
                    >
                      <Button
                        variant="btn-outline-primary"
                        onClick={toggleSidebar}
                        className="sidebar-toggle border btn btn-outline-primary btn-sm"
                      >
                        <span className="material-symbols-outlined">tune</span>
                      </Button>
                    </OverlayTrigger>
                  )}
                  {!readOnly && (
                    <>
                      <AssessmentProperties />
                      <AssessmentCreate />
                    </>
                  )}

                  {!showSidebar && (isMobileScreen || isTabletScreen) && (
                    <Card
                      className="border-0 bg-white position-sticky sticky-header"
                      style={{ height: "80vh", overflow: "scroll" }}
                    >
                      <h5 className="border-bottom border-opacity-25 border-secondary pb-3 ps-2 pt-3">
                        Filters
                      </h5>
                      <Card.Body>
                        <SidebarFilters
                          assessments={allAssessments}
                          fetchAssessments={fetchAssessments}
                          isUserLoading={isLoading}
                          selectedProperty={selectedProperty}
                          selectedStartDate={selectedStartDate}
                          selectedEndDate={selectedEndDate}
                          selectedEmail={selectedEmail}
                          setSelectedEmail={setSelectedEmail}
                          setSelectedEndDate={setSelectedEndDate}
                          setSelectedProperty={setSelectedProperty}
                          setSelectedStartDate={setSelectedStartDate}
                        />
                      </Card.Body>
                    </Card>
                  )}
                </div>
              </Col>
              <span>
                {(isLoading || hasFilterLoaded) && <CustomPlaceHolder />}
                {error && <ErrorHandler error={error} />}
              </span>
            </Row>
            {assessments && assessments.length === 0 && (
              <Alert variant="primary">No Assessments could be found.</Alert>
            )}
            {assessments &&
              assessments.length > 0 &&
              assessments.map((assessment, key) => {
                return (
                  <Card className="bg-transparent border-0" key={key}>
                    <Card.Body>
                      <Row className="py-3 border-top" key={key}>
                        <Col md={2} xs={2}>
                          {assessment.property?.image ? (
                            <Image
                              src={assessment.property?.image}
                              fluid
                              className="rounded"
                              style={{
                                width: "8.0415rem",
                                height: "5.25rem",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <div className="d-inline-flex bg-primary text-center rounded text-primary bg-opacity-25 w-100 py-2">
                              <div className="py-1 mx-auto">
                                <h1 className="mb-0">
                                  {assessment.name.charAt(0).toUpperCase()}
                                </h1>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md={7} xs={10}>
                          <div>
                            <span className="align-middle">
                              <h5 className="d-inline">
                                <Link
                                  to={`assessments/${assessment.id}`}
                                  className="text-decoration-none bold"
                                >
                                  {capitalizeFirstLetter(assessment.name)}
                                  <sub className="h-100">
                                    <Badge
                                      style={{ fontSize: 10 }}
                                      bg={
                                        assessment?.status?.status ===
                                        "Assessment Completed"
                                          ? "success"
                                          : "primary"
                                      }
                                      className="rounded-pill ms-1"
                                    >
                                      {assessment?.status?.status}
                                    </Badge>
                                  </sub>
                                </Link>
                              </h5>
                            </span>
                            <p className="mt-2 text-dark text-opacity-75 mb-0">
                              <span className="material-icons-outlined">
                                business
                              </span>{" "}
                              <span className="">
                                {assessment.property.name}
                              </span>
                            </p>
                            <span className="text-dark text-opacity-50 mt-2 mb-0">
                              {assessment?.email}
                            </span>
                            <br />
                            <small className="mb-3 text-dark text-opacity-50">
                              Last updated at{" "}
                              {new Date(assessment.updatedAt).toLocaleString(
                                [],
                                {
                                  dateStyle: "short",
                                  timeStyle: "short",
                                }
                              )}
                            </small>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })}
            <Row className="justify-content-center">
              <Col xs="auto">
                <PaginationComponent
                  currentPage={pagination.currentPage}
                  lastPage={pagination.lastPage}
                  onPageChange={handlePageChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AssessmentList;
