import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
  Form,
  Spinner,
  Card,
} from "react-bootstrap";

import TextLogo from "../TextLogo/TextLogo";
import { put } from "../../../utils/BeeApi";
import { isValidEmail } from "../../../utils/StringUtils";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { UserContext } from "../../../contexts/UserProvider";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";

const ForgotPassword = () => {
  const [error, setError] = useState();
  const [email, setEmail] = useState();
  const [success, setSuccess] = useState(false);
  const [emailValid, setEmailValid] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { user } = useContext(UserContext);
  const subscribedPromises = useRef([]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => promises.forEach((promise) => promise.cancel());
  }, []);

  const handleEmailChange = (event) => {
    let email = event.target.value;
    setEmail(email);
    setEmailValid(isValidEmail(email));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (emailValid) {
      setError();
      setIsLoading(true);

      const resetPromise = put("/login", {
        action: "forgot",
        email: email,
      });
      resetPromise.promise
        .then(() => {
          setError();
          setIsLoading(false);
          setSuccess(true);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(resetPromise);
    }
  };

  if (user) return <RedirectIfAuthenticated isAuthenticated={user} />;

  const renderForgotPasswordForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="text-center">
        <h2 className="pre-title">Request New Password</h2>
        <p>We&#x27;ll email you instructions to set your password.</p>
      </div>
      <hr />
      <Form.Group className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter email"
          isInvalid={!emailValid && formSubmitted}
          autoFocus
        />
        <Form.Control.Feedback type="invalid">
          This should be a valid email address
        </Form.Control.Feedback>
      </Form.Group>
      {error && <ErrorHandler error={error} />}
      {success && (
        <Alert variant="success">
          <h4>Success!</h4>
          <p>
            Please follow the instructions sent to the email you provided to
            reset your password.
          </p>
        </Alert>
      )}
      <div className="d-grid gap-2 mb-3">
        <Button
          type="submit"
          color="primary"
          disabled={isLoading || !emailValid}
        >
          {isLoading && (
            <Spinner
              className="me-2"
              animation="border"
              size="sm"
              variant="light"
            />
          )}
          Send Instructions
        </Button>
      </div>
      <p className="text-center">
        <Link to="/login">Cancel</Link>
      </p>
    </form>
  );

  return (
    <Container className="ForgotPassword my-3">
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <TextLogo />
          <Card className="shadow-sm">
            <Card.Body>{renderForgotPasswordForm()}</Card.Body>
          </Card>
          <p className="text-center text-sm pt-2 text-muted">
            {" "}
            &copy; ICAST {new Date().getFullYear()}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
