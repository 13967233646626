import React, { useEffect, useContext, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

import Login from "./Login/Login";
import Help from "../App/Help/Help";
import Signup from "./SignUp/SignUp";
import Logout from "./Logout/Logout";
import Loader from "../Loader/Loader";
import NoMatch from "./NoMatch/NoMatch";
import Content from "../Content/Content";
import Dashboard from "../Dashboard/Dashboard";
import ResetPassword from "./ResetPassword/ResetPassword";
import { UserContext } from "../../contexts/UserProvider";
import { UserProvider } from "../../contexts/UserProvider";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import History from "./History/History";

const Assessment = React.lazy(() => import("../Assessment/Assessment"));

const App = () => {
  return (
    <div className="Main">
      <UserProvider>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="" element={<PrivateRoute />}>
              <Route path="help" element={<Help />} />
              <Route path="logout" element={<Logout />} />
              <Route path="history" element={<History />} />
              <Route index path="/" element={<Dashboard />} />
              <Route path="contents/*" element={<Content />} />

              <Route
                path="assessments/*"
                element={
                  <Suspense fallback={<Loader />}>
                    <Assessment />
                  </Suspense>
                }
              />
            </Route>

            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  return user ? (
    <Outlet />
  ) : (
    <Navigate to="login" state={{ from: location }} replace />
  );
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export default App;
