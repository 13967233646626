import axios from "axios";
import { apiErrorHandler } from "./ApiErrorHandling";
import { MakeCancelable } from "./CancelablePromise";

const END_POINT = process.env.REACT_APP_DE_ENDPOINT;
const xsrfToken = Math.random().toString(36);

const httpOnlyConfig = {
  "XSRF-TOKEN": xsrfToken,
  version: "httpOnly",
};

let instance = axios.create({
  baseURL: END_POINT,
  headers: {
    common: { "XSRF-TOKEN": xsrfToken },
  },
  withCredentials: true,
  transformRequest: [
    (data) => {
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

const registerInterceptor = (instance) => {
  instance.interceptors.response.use(
    function (response) {
      return Promise.resolve(response.data);
    },
    function (error) {
      return apiErrorHandler(error);
    }
  );
};

const login = (data) => {
  const token = data.token;
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return instance
    .post("/login", { ...data, ...httpOnlyConfig }, options)
    .then((response) => {
      return Promise.resolve(response);
    });
};

const get = (url, data) => {
  return MakeCancelable(instance.get(url, { ...data, ...httpOnlyConfig }));
};

const logOut = () => {
  return instance
    .delete("/login", {
      params: { ...httpOnlyConfig },
    })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const post = (url, data) => {
  return MakeCancelable(instance.post(url, { ...data, ...httpOnlyConfig }));
};

const put = (url, data) => {
  return MakeCancelable(instance.put(url, { ...data, ...httpOnlyConfig }));
};

const destroy = (url, data = {}) => {
  return MakeCancelable(
    instance.delete(url, {
      params: httpOnlyConfig,
    })
  );
};

const upload = (url, data) => {
  return MakeCancelable(
    instance.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );
};

const download = (url, data) => {
  return MakeCancelable(
    instance.get(url, {
      responseType: "blob",
      contentType: "application/octet-stream",
      ...data,
    })
  );
};

registerInterceptor(instance);

export {
  login,
  get,
  logOut,
  put,
  post,
  destroy,
  upload,
  download,
  xsrfToken,
  httpOnlyConfig,
};
