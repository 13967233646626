import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Header from "../App/Header/Header";
import Footer from "../App/Footer/Footer";
import AssessmentList from "../Assessment/AssessmentList/AssessmentList";
import ReadOnlyAlert from "../App/ReadOnly";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Container className="MainContent py-5">
        <Row>
          <Col xs={12} className="mt-3">
            <span className="fs-5 me-2">{`Welcome back!`}</span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className="my-3">
            <Card className="border-0 bg-transparent">
              <Card.Body className="px-lg-3">
                <AssessmentList />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ReadOnlyAlert />
    </>
  );
};

export default Dashboard;
