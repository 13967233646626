import React, { useContext } from "react";
import { createPortal } from "react-dom";
import { UserContext } from "../../../contexts/UserProvider";
import { Alert } from "react-bootstrap";

function ReadOnlyAlert() {
  const {
    user: { readOnly },
  } = useContext(UserContext);
  return (
    <>
      {readOnly &&
        createPortal(
          <>
            <Alert
              variant="danger"
              className={` rounded   position-fixed translate-middle start-50 border-0  border-5 bottom-0 d-flex
            }`}
              style={{ zIndex: 10000 }}
            >
              <span className="material-symbols-outlined fw-medium">
                visibility_lock
              </span>
              <span className="ps-2 fw-medium">
                You are currently viewing as Read only member
              </span>
            </Alert>
          </>,
          document.body
        )}
    </>
  );
}

export default ReadOnlyAlert;
