import React, { useState, useEffect, useRef } from "react";
import styles from "./CountDownTimer.module.css";

const CountDownTimer = () => {
  const [count, setCount] = useState(5);
  const timer = useRef(null);
  useEffect(() => {
    function clearCountdown(interval) {
      clearTimeout(interval);
    }

    function countdown() {
      let countdownBegin = 5;
      let count = setInterval(function () {
        console.log(countdownBegin);

        if (countdownBegin <= 1) {
          clearCountdown(count);
          timer.current.innerHTML = 1;
        } else {
          --countdownBegin;
          timer.current.innerHTML = countdownBegin;
        }
      }, 1000);
    }

    countdown();

    return () => {
      clearCountdown();
    };
  }, []); // Empty dependency array to run the effect only once

  return (
    <span className="position-relative mx-4 my-2">
      <svg width="50" height="50" className={styles["counter-svg"]}>
        <circle className={styles.circle} cx="25" cy="25" r="15" />
      </svg>
      <span className={styles.timer} ref={timer}>
        5
      </span>
    </span>
  );
};

export default CountDownTimer;
