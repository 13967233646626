import React, { useEffect, useRef, useState, useCallback } from "react";
import { Navbar, Modal, Form, Alert } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { get } from "../../../utils/BeeApi";
import ContentDetails from "../../Content/ContentDetails/ContentDetails";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

const Help = () => {
  const isMobileScreen = useCheckMobileScreen();
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [contents, setContents] = useState([]);
  const [contentDetails, setContentDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const submitSearchTimeout = useRef(null);
  const subscribedPromises = useRef([]);

  useEffect(() => {
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  const getContent = () => {
    setError(null);
    const contentIdsPromise = get(`contents`, { params: { limit: 50 } });

    contentIdsPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setContents(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });

    subscribedPromises.current.push(contentIdsPromise);
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const getSearhContents = (searchItem) => {
    const searchContentsPromise = get(`/search?q=${searchItem}`);
    setError(null);

    searchContentsPromise.promise
      .then((response) => {
        const refinedData = response.data.filter(
          (content) => content.listed === true
        );
        setContents(refinedData);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(searchContentsPromise);
  };

  const showContents = useCallback(
    (id) => () => {
      const contentPromise = get(`contents/${id}`, {});
      contentPromise.promise.then((response) => {
        setContentDetails(response.data);
      });
    },
    []
  );

  const handleShowModal = () => {
    setIsLoading(true);
    getContent();
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
    setIsLoading(true);
    setContents([]);
  };

  const handleShow = (searchItem) => {
    setSearchTerm(searchItem);
    setIsLoading(true);
    clearTimeout(submitSearchTimeout.current);

    submitSearchTimeout.current = setTimeout(() => {
      if (searchItem) {
        getSearhContents(searchItem);
      } else {
        getContent();
      }
    }, 5000);
  };

  return (
    <>
      <Navbar.Text
        className="me-3"
        onClick={handleShowModal}
        style={{ cursor: "pointer" }}
      >
        Help <span className="material-icons-outlined">help_outline</span>
      </Navbar.Text>
      <Modal
        show={show}
        backdrop={handleCloseModal}
        onHide={handleCloseModal}
        className={`${isMobileScreen ? null : "right"}`}
        fullscreen="sm-down"
      >
        <Modal.Header closeButton className="sticky-top bg-light">
          <Modal.Title as={"div"}>
            <h4>Help Center</h4>
            <small className="mb-4">
              Articles for getting the most out of Energy Intel.
            </small>

            <Form className="d-flex mt-4" onSubmit={submitHandler}>
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2 mb-2"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => {
                  handleShow(e.target.value);
                }}
              />
            </Form>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader error={isLoading} />}
          {error && <ErrorHandler error={error} />}
          {contents.length === 0 && !isLoading && (
            <Alert variant="primary" className="mt-2 mb-2">
              <p>No results found</p>
            </Alert>
          )}
          <Accordion className="custom-accordion">
            {contents.map((content, key) => {
              return (
                <Accordion.Item eventKey={key} key={key}>
                  <Accordion.Header onClick={showContents(content.contentId)}>
                    <small>{content.title}</small>
                  </Accordion.Header>

                  <Accordion.Collapse eventKey={key}>
                    <Accordion.Body>
                      {contentDetails.contentId === content.contentId && (
                        <ContentDetails
                          contentId={contentDetails.contentId}
                          view="full"
                        />
                      )}
                      <Link
                        to={`/contents/${content.contentId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View content on full screen
                      </Link>
                    </Accordion.Body>
                  </Accordion.Collapse>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Help;
